"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import SubscribeCard from "~/components/subscribe-card-client";
import OutlineButton from "~/components/reusables/outline-button";
import AspectRatioComponent from "~/components/aspect-ratio";
import { ArrowUpRight } from "~/components/icons";
import { Button } from "components/ui/button";

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
const BlogList = ({ blogs, subscribeCard }) => {
  const [gridColumnNumber, setGridColumnNumber] = React.useState(1);
  const articles = [...blogs.attributes.blogs.data, {}];

  const size = useWindowSize();
  useEffect(() => {
    if (size.width! > 1024) {
      setGridColumnNumber(3);
    } else if (size.width! > 640) {
      setGridColumnNumber(2);
    } else {
      setGridColumnNumber(1);
    }
  }, [size.width]);
  return (
    <div className="container">
      <ul
        className="grid bg-white pt-[50px]"
        style={{
          gridTemplateColumns: `repeat(${gridColumnNumber}, 1fr)`,
        }}
      >
        {articles.map((blog, index) => {
          const isFirstColumn = index % gridColumnNumber === 0;
          const isSecondItem = index === 1;
          const isLastItem = index === articles.length - 1;
          const isLastRow =
            index >= articles.length - (articles.length % gridColumnNumber);
          const isFirstRow = index < gridColumnNumber;

          return (
            <li key={`${blog.id} - ${index}`} className="relative bg-white">
              <div className="absolute top-0 z-10 h-[1px] w-full bg-rainbow-r2"></div>
              {isLastItem && (
                <div className="absolute right-0 z-10 h-full w-[1px] bg-rainbow-b2"></div>
              )}
              {!isLastItem && (
                <div className="absolute right-0 z-10 h-full w-[1px] bg-rainbow-b2"></div>
              )}
              {!isLastRow && (
                <div className="absolute bottom-0 z-10 h-[1px] w-full bg-rainbow-r2"></div>
              )}
              <div className="absolute left-0 z-10 h-full w-[1px] bg-rainbow-b2"></div>

              {isFirstColumn && (
                <Image
                  src="/images/graphics/right-triangle.png"
                  alt="Right Triangle"
                  width="0"
                  height="0"
                  unoptimized
                  className="absolute left-0 top-[-7px] z-[11] h-auto w-[8.5px]"
                />
              )}
              {isFirstRow && !isFirstColumn && (
                <Image
                  src="/images/graphics/down-triangle.png"
                  alt="Another Image"
                  width="0"
                  height="0"
                  unoptimized
                  className="absolute left-[-8px] top-0 z-[11] h-auto w-[17px]"
                />
              )}
              {isFirstRow && isSecondItem && (
                <div className="absolute right-0 top-[-150px] z-0 h-[150px] w-[1px] bg-rainbow-b2"></div>
              )}
              {!isFirstRow && !isFirstColumn && !isLastRow && (
                <Image
                  src="/images/graphics/middle-diamond.png"
                  alt="Another Image"
                  width="0"
                  height="0"
                  unoptimized
                  className="absolute left-[-8px] top-[-8px] z-[11] h-auto w-[17px]"
                />
              )}
              {blog.attributes ? (
                <div className="pt-3">
                  <Link
                    href={`/blog/${blog?.attributes?.key?.toLowerCase()}`}
                    className="group block h-full "
                  >
                    {blog.attributes.blog_category.data && (
                      <div className="mb-1 flex gap-3 px-4 py-6 lg:px-[30px]">
                        <Image
                          unoptimized
                          src={
                            blog.attributes?.blog_category?.data?.attributes
                              ?.icon?.data?.attributes.url
                          }
                          alt="Icon"
                          width="25"
                          height="25"
                        />
                        <h6 className="font-h6 uppercase">
                          {
                            blog.attributes?.blog_category?.data?.attributes
                              ?.title
                          }
                        </h6>
                      </div>
                    )}
                    <div className="mb-[100px] h-[300px] w-full px-4 md:mb-[130px] md:h-[400px] lg:px-[30px]">
                      <div className="relative mx-auto overflow-hidden rounded-xl md:min-h-[180px]">
                        <AspectRatioComponent
                          aspectRatio={{
                            width:
                              blog.attributes?.thumbnail.data.attributes.width,
                            height:
                              blog.attributes?.thumbnail.data.attributes.height,
                          }}
                        >
                          <Image
                            src={blog.attributes?.thumbnail.data.attributes.url}
                            fill
                            alt="Featured home blog"
                            className="w-[90%] rounded-xl object-cover transition-all duration-700 group-hover:scale-105"
                            sizes="100%"
                            unoptimized
                          />
                        </AspectRatioComponent>
                      </div>
                      <h3 className="font-h5 mt-5 group-hover:underline group-hover:underline-offset-2">
                        {blog.attributes?.title}
                      </h3>
                    </div>
                  </Link>
                  <div className="absolute bottom-6 flex flex-wrap gap-5 px-5 pt-5 md:bottom-8 md:px-[30px] md:pt-14 ">
                    {blog.attributes?.blog_topics?.data &&
                      blog.attributes?.blog_topics?.data.map((t, i) => (
                        <Button
                          asChild
                          key={i}
                          variant="secondary"
                          aria-label="link"
                          className="h-7 rounded-full bg-black px-[12px] py-[4px] text-[10px] font-bold text-white hover:bg-gray-950"
                        >
                          <Link
                            href={`/blog/topic/${t.attributes.key.toLowerCase()}`}
                            className="pb-0 pt-1 text-[10px] font-bold uppercase"
                            onClick={() => {
                              localStorage.setItem("currentPage", "1");
                            }}
                          >
                            {t.attributes.title}
                          </Link>
                        </Button>
                      ))}
                  </div>
                </div>
              ) : (
                <Link
                  href={blogs.attributes.linkBlockLink}
                  className="group block h-[300px] w-full px-4 pb-[100px] pt-10 md:h-full md:pb-[130px] lg:px-[30px]"
                >
                  <div className="flex h-full flex-col justify-between px-8">
                    <h3 className="font-h1 w-[80%] text-left ">
                      {blogs.attributes.linkBlockText}
                    </h3>
                    <div className="absolute bottom-4 right-6">
                      <OutlineButton variant={"light"}>
                        <div className="inline-block text-black">
                          <ArrowUpRight size={50} color="black" />
                        </div>
                      </OutlineButton>
                    </div>
                  </div>
                </Link>
              )}
              {/* </div> */}
            </li>
          );
        })}
        <li className="md:hidden">
          <SubscribeCard
            subscribeCard={subscribeCard}
            segmentOpt={{
              link_label: "Subscribe to our newsletter",
              location: "body",
              location_detail: "right-body",
              resource_interaction: "view newsletter subscription form",
            }}
          />
        </li>
      </ul>
    </div>
  );
};

export default BlogList;
