import React from "react";
import Image from "next/image";
import { AspectRatio } from "components/ui/aspect-ratio";
import { LinkTrackers } from "~/components/reusables/link-trackers";

const ProductCarouselCard = ({ product, index }) => {
  const productLogo = product.attributes.productLogo.data[0].attributes;
  const productName = product.attributes.productName;
  const calcWidth = (35 * productLogo.width) / productLogo.height;
  const calcWidthClass = "max-w-[" + calcWidth.toFixed().toString() + "px] ";

  return (
    <LinkTrackers
      href={product.attributes.ui[0].url}
      target="_blank"
      segmentMsg="learn more clicked"
      segmentOpt={{
        link_label: "Learn more",
        location: "body",
        location_details: `carousel ${index + 1}`,
        resource_interaction:
          (productName === "AGNTCY" && "visit the agntcy homepage") ||
          (productName === "Internet of Agents" &&
            "visit to the internet of agent page"),
      }}
    >
      <div
        className={`relative flex items-center ${productName == "Internet of Agents" && "lg:items-start"} ${productName == "AGNTCY" && "bg-[#00142B]"} h-[295px] justify-center overflow-hidden`}
      >
        <div className={`relative w-full overflow-hidden md:min-h-[160px]`}>
          <div
            className={`flex ${productName == "Internet of Agents" && "w-[420px]"} flex-col items-center sm:w-full`}
          >
            <AspectRatio
              ratio={
                product.attributes?.productThumbnail.data.attributes.width /
                product.attributes?.productThumbnail.data.attributes.height
              }
            >
              <Image
                unoptimized
                src={product.attributes?.productThumbnail.data.attributes.url}
                fill
                alt="Product Thumbnail"
                className="w-full object-cover transition-all duration-700 group-hover:scale-105"
                sizes="100%"
              />
            </AspectRatio>
          </div>
        </div>
      </div>
      <div className="container py-10 lg:p-14">
        <div className="flex items-center justify-between gap-4">
          <div
            className={`${productName == "Internet of Agents" && "max-w-[300px]"} ${productName == "AGNTCY" && "max-w-[150px]"} relative h-auto max-h-[35px] w-2/3 lg:w-full`}
          >
            <AspectRatio ratio={productLogo.width / productLogo.height}>
              <Image
                src={productLogo.url}
                alt="Product Logo"
                sizes="100%"
                fill
                unoptimized
                className=""
              />
            </AspectRatio>
          </div>
          <div className="relative lg:hidden">
            <Image
              src="/images/graphics/link-graphic-white.png"
              alt="learn more"
              width={39}
              height={33}
              unoptimized
              className="h-auto w-full"
            />
          </div>
          <div className="hidden items-center justify-around gap-1 lg:flex">
            <h6 className="w-[100px] text-[14px] text-white group-hover:underline">
              LEARN MORE
            </h6>
            <div>
              <Image
                src="/images/graphics/link-graphic-white.png"
                alt="learn more"
                width={39}
                height={33}
                unoptimized
                className="h-auto w-full"
              />
            </div>
          </div>
        </div>
        <div className="mt-10 gap-5 text-white md:gap-8 lg:grid lg:grid-cols-2 lg:gap-10">
          <p className="font-h4 lg:mt-5 lg:text-[30px]">
            {product.attributes?.productSubheader}
          </p>
          <p className="font-p mb-5 mt-5">
            {product.attributes?.productDescription}
          </p>
        </div>
      </div>
    </LinkTrackers>
  );
};

export default ProductCarouselCard;
